<template>
    <div class="yj-conten" style="background-color:#fff;width:calc(100% - 32px); min-width:fit-content">
        <a-layout-content style="padding:0;">
            <div class="yj-conten " style="background: #fff;padding: 20px 20px 0;margin: 0;">
                <a-row class="yj-form">
                    <a-col :span="20">

                    </a-col>
                    <a-col :span="4" class="textal_r">
                        <a-button type="primary" @click="choose(itemIndex)">使用模板</a-button>
                    </a-col>
                </a-row>
                <a-table :columns="columns" bordered
                         rowKey="ID"
                         :data-source="tableData"
                         :pagination="pagination"
                         @change="pagerChange"
                         style=" min-height: calc(100vh - 234px);min-width:max-content"
                         class="yj-table-img">
                    <span slot="hlevel" slot-scope="text, record" style="font-weight:bolder;">
                        LV{{record.Rule.HonorLevel}}
                    </span>
                    <span slot="InfoReport" slot-scope="text, record">
                        <img style="width:50px; height:50px;" :src="Getsrc(record)">
                        <br />
                        <small>{{record.Rule.HonorName}}</small>
                    </span>
                    <div slot="action" slot-scope="text, record" style="text-align:left;line-height:1.6rem;">
                        <span v-for="itemIndex in record.RuleIndex" :key="itemIndex.ID">
                            {{itemIndex.IndexName}}积分：+{{itemIndex.Integral}}
                            <br />
                        </span>
                    </div>
                </a-table>
            </div>
        </a-layout-content>
    </div>
</template>
<script>
    import http from "@/Plugin/Http.js";

    export default {
        name: "M2Activity_List",
        data() {
            return {
                modalVisible:false,
         columns: [
                    {
                        title: "荣誉等级",
                        dataIndex: "Rule.HonorLevel",
                        align: "center",
                        width: 90,
                        scopedSlots: { customRender: 'hlevel' }
                    },
                    {
                        title: "名称",
                        width: 90,
                        align: "center",
                        dataIndex: "Rule.HonorName",
                        scopedSlots: { customRender: 'InfoReport' }
                    },
                    {
                        title: "积分",
                        width: 90,
                        align: "center",
                        dataIndex: "Rule.Integral",
                        //scopedSlots: { customRender: 'AwardPool' }
                    },               
                    {
                        title: '目标',
                        align: "center",
                        width: 150,
                        scopedSlots: { customRender: 'action' }
                        //customRender: (text, item) => {
                           
                        //    var str = "<div>";
                        //    for (var i = 0; i < item.RuleIndex.length;i++)
                        //    {
                        //        str = str + "<span>" + item.RuleIndex[i].IndexName + ":" + item.RuleIndex[i].LimitCount+"</span>";

                        //        console.log(item.RuleIndex[i].IndexName);
                        //    }
                        //    str = str + "</div>";

                        //    return str;
                        //}
                      
                    },
                    //{
                    //    title: "设置",
                    //    width: 90,
                    //    align: "center",
                    //    dataIndex: "Rule.Cash",
                    //    scopedSlots: { customRender: 'op' }
                    //}
                ],
                tableData: [], 
                templateData:[],
                pagination: {
                    total: 0,
                    defaultPageSize: 0,
                    current: 1,
                    showTotal: total => `共 ${total} 条数据`
                },
                formItemLayout: {
                    labelCol: {
                        xs: { span: 24 },
                        sm: { span: 2 },
                    },
                    wrapperCol: {
                        xs: { span: 24 },
                        sm: { span: 22 },
                    },
                },
            };
        },
        props: {//组件属性
        },
        methods: {
            getTableData: function () {
                var self = this;
                console.log(self.$route.query.ID)
                var op = {
                    url: "/PartnerModule/PartnerHonor/GetPartnerHonorItemListTmpId",
                    data: {
                        TemplateID: self.$route.query.ID
                    },
                    OnSuccess: function (data) {
                        console.log(data.data);
                         self.tableData = data.data;
               
                    }
                };
                http.Post(op);
            },
            Getsrc: function (record) {
                return "/image/honor/" + record.Rule.HonorLevel + ".png";
            },
            search: function () {
                var self = this;
                self.pagination.current = 1;
                self.getTableData();
            },
            addRow() {
                var self = this;
                self.$router.push({
                    name: "PartnerHonor_Form",
                    query: { ID: 0 }
                });
            },
            pagerChange: function (item) {
                var self = this;
                self.pagination.current = item.current;
                self.getTableData();
            },
            choose: function () {
                var self = this;
                var id = self.$route.query.ID;
                var op = {
                    url: "/PartnerModule/PartnerHonor/ChooseTemplate",
                    data: {
                        id: id
                    },
                    OnSuccess: function (data) {
                        console.log(data);
                        if (data.data) {
                            self.$router.push({
                                name: "PartnerHonor_List",
                                query: {}
                            });
                        } else {
                            alert("选择模板失败,请合理配置模板积分");
                        }
                    }
                };
                http.Post(op);
            },
        },
        watch: {//侦听属性
        },        
        mounted: function () {//生命周期，组装完成
            this.getTableData();
        }
 
    };
</script>